var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageTitleHeader',{staticClass:"h-24 lg:h-56",attrs:{"pageAccessor":'attendeeSearchResult'}},[_c('h1',{staticClass:"mb-0 title mg-title-header text-center mg-font-bold uppercase text-lg md:text-4xl xl:text-5xl"},[_vm._v(" "+_vm._s(_vm.textHeader)+" ")])]),(_vm.isLoading)?_c('Spinners',{staticClass:"py-8"}):[(_vm.attendeeSearchResults.length)?_c('section',{staticClass:"p-8 md:pt-16"},[_c('div',{staticClass:"container content"},[_c('div',{staticClass:"text-center max-w-md mx-auto"},[_c('p',{staticClass:"is-size-4 mb-0 font-semibold"},[_vm._v(" Here are the people you really need to meet and network with! ")])])])]):_vm._e(),_c('section',{staticClass:"section is-centered md:p-8"},[_c('div',{staticClass:"container px-4 md:px-0"},[_c('div',{staticClass:"notification",class:{
                        'is-danger': _vm.attendeeSearchResults.length == 0,
                        'is-success': _vm.attendeeSearchResults.length
                    }},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.resultsRange)}})]),_c('div',{staticClass:"flex flex-col"},[(_vm.shouldShowPagination)?_c('div',{staticClass:"w-full md:w-1/2 mb-4"},[_c('b-pagination',{attrs:{"total":_vm.totalResults,"per-page":20,"range-before":2,"range-after":2,"current":_vm.currentPage,"order":"is-centered"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-pagination-button',{attrs:{"page":props.page,"id":("page" + (props.page.number)),"tag":"router-link","to":_vm.getRouteObjForPage(
                                            props.page.number
                                        )}})]}}],null,false,2455205649)})],1):_vm._e(),_c('div',{staticClass:"w-full mb-10 md:mb-0 md:ml-4 flex justify-center md:justify-end"},[_c('button',{staticClass:"w-full md:w-1/4 inline-block button pill-button is-primary has-text-weight-semibold",on:{"click":function($event){$event.preventDefault();return _vm.goToSearch()}}},[_vm._v(" BACK TO SEARCH ")])])]),_c('div',{staticClass:"column has-text-weight-light m-auto"},[_c('div',_vm._l((_vm.attendeeSearchResults),function(attendee){return _c('attendee-card',{key:attendee.attendeeId,attrs:{"data":attendee,"layout":"row"}})}),1)]),(_vm.totalResults > 5)?_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"w-full md:w-1/2 mb-4"},[(_vm.shouldShowPagination)?_c('b-pagination',{attrs:{"total":_vm.totalResults,"per-page":20,"range-before":2,"range-after":2,"current":_vm.currentPage,"order":"is-centered"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-pagination-button',{attrs:{"page":props.page,"id":("page" + (props.page.number)),"tag":"router-link","to":_vm.getRouteObjForPage(
                                            props.page.number
                                        )}})]}}],null,false,2455205649)}):_vm._e()],1),_c('div',{staticClass:"w-full md:w-1/2 md:ml-4 flex justify-center md:justify-end mb-4"},[_c('button',{staticClass:"w-full md:w-1/2 inline-block button pill-button is-primary has-text-weight-semibold",on:{"click":function($event){$event.preventDefault();return _vm.goToSearch()}}},[_vm._v(" BACK TO SEARCH ")])])]):_vm._e()])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }