


































































































































// import { Component, Vue, Watch } from "vue-property-decorator";
import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import router from "@/router";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";
import attendeeSearchVuexModule from "@/store/vuex-modules/searchAttendeeData";
import Spinners from "@/components/utilities/Spinners.vue";

import { AttendeeSearchInput } from "@/types/interfaces";

const attendeeSearchStore = getModule(attendeeSearchVuexModule);

/**
 * These are set as optional and nullable. This is because we want to be able to quickly
 * get a list of keys, but not include keys if we don't need them.
 */
class GtmAttendeeSearchData {
    event = "";
    matchFirstName?: string | null = null;
    matchLastName?: string | null = null;
    matchTitle?: string | null = null;
    matchCity?: string | null = null;
    matchState?: string | null = null;
    matchCompanyName?: string | null = null;
    matchCountry?: string | null = null;
    resultCount?: string | null = null;
}

export default Vue.extend({
    components: {
        PageTitleHeader,
        AttendeeCard,
        Spinners
    },
    data() {
        return {
            validSearchParams: [
                "matchFirstName",
                "matchLastName",
                "matchCompanyName",
                "matchCompanyType",
                "matchCountry",
                "matchCity",
                "matchState",
                "matchTitle",
                "matchSpecialInterest",
                "matchSecondaryInterest",
                "matchSystem",
                "matchSchool",
                "matchGraduationYear",
                "matchAttendeeBanner",
                "page"
            ]
        };
    },
    computed: {
        layoutOptions(): Record<string, any> {
            return store.getters.layoutOptions;
        },
        cssVariables(): Record<string, string> {
            return store.getters.cssVariables;
        },
        attendeeSearchResults(): Array<any> {
            if (attendeeSearchStore.attendeeSearchResults) {
                return attendeeSearchStore.attendeeSearchResults;
            } else {
                return [];
            }
        },
        textHeader(): string {
            return store.getters.getPageOptions("attendeeResults")?.textHeader
                ? store.getters.getPageOptions("attendeeResults").textHeader
                : "Attendee Search Results";
        },
        routeQuery(): Record<string, any> {
            return this.$route.query ? this.$route.query : {};
        },
        searchInput(): AttendeeSearchInput {
            const query = this.routeQuery as any;
            const input = {} as AttendeeSearchInput;
            Object.keys(query).forEach((key) => {
                const itemKey = key as keyof AttendeeSearchInput;
                if (this.validSearchParams.includes(key)) {
                    input[itemKey] = query[key];
                }
            });
            return input;
        },
        isLoading(): boolean {
            return attendeeSearchStore.loading;
        },
        totalResults(): number | string {
            return attendeeSearchStore.totalResults;
        },
        currentPage(): string | number {
            const searchInput = this.searchInput as AttendeeSearchInput;
            return searchInput.page ? parseInt(searchInput.page) : 1;
        },
        resultsRange(): number | string {
            const totalResults: string | number = +this.totalResults;
            if (totalResults < 1) {
                return "<b>0</b> results found";
            }
            const first: number = ((+this.currentPage - 1) * 20 + 1) as number;
            const last = +this.currentPage * 20;
            const actualLast =
                last > this.totalResults ? this.totalResults : last;
            const resultWord = this.totalResults === 1 ? "result" : "results";
            return `${first} - ${actualLast} of <b>${this.totalResults}</b> ${resultWord} found`;
        },
        shouldShowPagination(): boolean {
            return this.totalResults >= 21;
        }
    },
    watch: {
        searchInput: function() {
            this.getSearchResults();
        }
    },

    /**
     * Lifecycle hooks
     */
    mounted() {
        this.getSearchResults();
    },

    /**
     * Methods
     */
    methods: {
        async getSearchResults(): Promise<void> {
            await attendeeSearchStore.searchAttendees(this.searchInput);
            this.gtmTrack();
        },
        goToSearch() {
            router.push("/attendees");
        },
        getRouteObjForPage(page: number) {
            const query = this.$route.query ? { ...this.$route.query } : {};
            query.page = `${page}`;
            return {
                path: "/attendees/results",
                query: query
            };
        },
        gtmTrack() {
            window.dataLayer = window.dataLayer || [];
            const trackObj = {
                event: "attendee_search"
            } as GtmAttendeeSearchData;

            const allKeys = Object.keys(new GtmAttendeeSearchData()) as Array<
                keyof GtmAttendeeSearchData
            >;

            const optionalKeys = allKeys.filter((key) => {
                const removeKeys = ["event", "resultCount"];
                return !removeKeys.includes(key);
            });

            optionalKeys.forEach((key) => {
                const data = this.searchInput[key as keyof AttendeeSearchInput];
                if (data) {
                    trackObj[key] = data as string;
                }
            });

            trackObj.resultCount = `${this.totalResults}`;

            window.dataLayer.push(trackObj);
        }
    }
});
